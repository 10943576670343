<template>
  <div>
    <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true"
            >Болих</el-button
          >
          <el-button type="success" @click="onPressNewProdSupplier('supplierData')">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row v-if="!pageStatus.error" v-loading="loading">
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <custom-header :title="'Нийлүүлэгчийн мэдээлэл засах'"/>
        <div class="form-error-container mb20" v-if="errorArray.length != 0">
          <header>Уучлаарай {{errorArray.length}} зүйл алдаатай байна</header>
          <ul class="error-list">
            <div v-for="(error, index) in errorArray" :key="index">
              <li>{{error.value}}</li>
            </div>
          </ul>
        </div>
        <el-form label-position="top" :model="supplierData" :rules="supplierRules" ref="supplierData">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Монгол нэр" prop="supplier_monName">
                        <el-input v-model="supplierData.supplier_monName" placeholder="Говь ХХК ..."></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Англи нэр" prop="supplier_engName">
                      <el-input v-model="supplierData.supplier_engName" placeholder="Говь ХХК ..."></el-input>
                    </el-form-item>
                  </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Регистрийн дугаар" prop="supplier_regno">
                        <el-input v-model="supplierData.supplier_regno" placeholder="6183352" v-on:input="numberValidate()"></el-input>
                      </el-form-item>
                    </el-col>
                     <el-col :span="12">
                        <el-form-item label="Байгууллага">
                          <el-input placeholder="" disabled type="text" v-model="supplierData.EbarimtSupplier"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item label="Утасны дугаар" prop="phone">
                          <el-input v-model="supplierData.phone" placeholder="99999999"></el-input>
                        </el-form-item >
                      </el-col>
                    </el-row>
                      <el-form-item label="Хаяг" prop="address">
                    <el-input v-model="supplierData.address" type="textarea" rows="3" placeholder="ХУД 3-р хороо Гоёо"></el-input>
                  </el-form-item>
                  <el-form-item label="Байршил">
                    <el-input v-model="supplierData.location" disabled></el-input>
                  </el-form-item>
                  <div class="" style="width: 100%; height: 400px; display:block">
                    <gmap-map
                      :center="googleMap.center"
                      :zoom="12"
                      :options="googleMap.options"
                      :draggable="false"
                      style="width:100%;  height: 100%; margin-top: 5px;"
                    >
                    <gmap-marker
                      :position="googleMap.location"
                      :clickable="true"
                       @position_changed="changeLocation($event)"
                      :draggable="true"/>
                    </gmap-map>
                  </div>
                </div>
              </div>
            </el-col>
            <!-- Right brands -->
            <el-col :span="8">
              <div class="panel">
                <div class="panel-item">
                  <header>Нийлүүлэгчийн брэндүүд</header>
                  <el-table
                  :data="supplierData.brands"
                  style="width: 100%">
                  <el-table-column
                    prop="brand_monName"
                    label="Брэнд"
                    width="180"
                    align="center">
                  </el-table-column>
                  <el-table-column
                    label="Зураг"
                    align="center">
                     <template slot-scope="scope">
                      <el-row :gutter="10" align="middle" type="flex">
                        <el-col :span="12" :offset="6">
                          <div class="image-holder">
                            <img :src="scope.row.brand_logo">
                           </div>
                        </el-col>
                      </el-row>
                    </template>
                  </el-table-column>
                  </el-table>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-button class="text-left" type="danger" @click="confirmDeleteSupplierDialog = true">Устгах</el-button>
              </el-col>
              <el-col :span="16" class="text-right">
                <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
                <el-button type="success" @click="onPressNewProdSupplier('supplierData')
                ">Хадгалах</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/supplier">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
    <!-- delete -->
    <el-dialog
      title="Нийлүүлэгч устгах"
      :visible.sync="confirmDeleteSupplierDialog"
      width="50%"
    >
      <div>
        <strong>{{supplierData.supplier_monName}}</strong> нийлүүлэгчийг устгахдаа итгэлтэй байна уу?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmDeleteSupplierDialog = false" class="mr10"
          >Болих</el-button
        >
        <el-button type="danger" @click="deleteSupplier(supplierData.supplier_id)">
          Устгах
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import customHeader from '../../../components/common/customHeader'
import services from '../../../helpers/services'
export default {
  components: {
    customHeader: customHeader
  },

  data () {
    return {
      loading: false,
      confirmDeleteSupplierDialog: false,
      confirmLeaveForm: false,
      errorArray: [],
      pageStatus: {
        error: false,
        message: ''
      },
      supplierData: {
        brands: [],
        supplier_monName: '',
        supplier_engName: '',
        supplier_regno: '',
        EbarimtSupplier: '',
        phone: '',
        address: '',
        location: '47.9174, 106.9137',
        supplier_id: ''
      },
      googleMap: {
        center: {
          lat: 47.9174,
          lng: 106.9137
        },
        location: {
          lat: 47.9174,
          lng: 106.9137
        },
        markerOptions: {
          size: { width: 100, height: 90, f: 'px', b: 'px' },
          scaledSize: { width: 45, height: 45, f: 'px', b: 'px' }
        },
        options: {
          scrollwheel: true,
          mapTypeControl: true,
          streetViewControl: false,
          minZoom: 4,
          maxZoom: 20,
          gestureHandling: 'greedy'
        }
      },
      supplierRules: {
        supplier_monName: [
          { required: true, message: 'Байгууллагын монгол нэрээ оруулна уу', trigger: 'blur' }
        ],
        supplier_engName: [
          { required: true, message: 'Байгууллагын англи нэрээ оруулна уу', trigger: 'blur' }
        ],
        supplier_regno: [
          { required: true, message: 'Байгууллагын регистрийн дугаараа бөглөнө үү', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: 'Утасны дугаараа оруулна уу', trigger: 'blur' },
          { min: 6, max: 8, message: 'Утасны дугаараа бүрэн оруулна уу', trigger: 'blur' }
        ],
        address: [
          { required: true, message: 'Хаягаа оруулна уу', trigger: 'blur' }
        ]
      }
    }
  },

  created () {
    if (this.$route.params.supplier_id) {
      this.getOneSupplier(this.$route.params.supplier_id)
    }
  },
  methods: {
    numberValidate () {
      if (this.supplierData.supplier_regno.length >= 7 && this.supplierData.supplier_regno.length <= 10) {
        this.checkEbarimt()
      }
    },

    checkEbarimt () {
      services.checkEbarimt(this.supplierData.supplier_regno).then(response => {
        if (response.found === true) {
          this.supplierData.EbarimtSupplier = response.name
          this.$forceUpdate()
        }
      })
    },

    changeLocation (event) {
      this.supplierData.location = event.lat() + ', ' + event.lng()
    },

    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },

    onPressNewProdSupplier (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.supplierData.supplier_id !== '') {
            services.updateSupplier(this.supplierData).then(data => {
              if (data.status === 'success') {
                this.alertReporter('Амжилттай', data.message, 'success')
                this.loading = false
                this.$router.push({ name: 'supplier' })
              } else if (data.status === 'error') {
                this.alertReporter('Алдаа', data.message, 'error')
                this.loading = false
              }
            })
          } else {
            services.addSupplier(this.supplierData).then(data => {
              if (data.status === 'success') {
                this.alertReporter('Амжилттай', data.message, 'success')
                this.loading = false
                this.$router.push({ name: 'supplier' })
              } else if (data.status === 'error') {
                this.alertReporter('Алдаа', data.message, 'error')
                this.loading = false
              }
            })
          }
        } else {
          console.log('error submit!!!!!')
          return false
        }
      })
    },

    deleteSupplier (id) {
      this.loading = true
      services.deleteSupplier({ supplierId: id }).then(response => {
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', `${id} дугаартай нийлүүлэгч устлаа`, 'success')
          this.loading = false
          this.$router.push({ name: 'supplier' })
        } else if (response.status === 'error') {
          this.alertReporter('Алдаа', `${response.message}`)
          this.loading = false
        }
      })
    },

    getOneSupplier (id) {
      services.getOneSupplier(id).then(response => {
        if (response.status === 'success') {
          this.supplierData = response.data
          const location = this.supplierData.location.split(',')
          this.googleMap.location.lat = parseFloat(location[0])
          this.googleMap.location.lng = parseFloat(location[1])
          this.loading = false
        } else if (response.status === 'error') {
          this.pageStatus.error = true
          this.pageStatus.message = response.message
        }
      })
    }
  }

}
</script>

<style></style>
